<template>
    <v-card class="ma-1 favorite_card">
        <v-col cols="12">
            <div class="d-flex justify-center" @click="detailEvent">
                <img :src="row.product_img"
                       height="70px"
                       width="70px"
                />
            </div>

            <div class="favorite_position">
                <v-btn icon
                       small
                       color="#ff0000"
                       @click="favoriteDelete(row)"
                >
                    <v-icon>favorite</v-icon>
                </v-btn>
            </div>

            <div class="mt-2" @click="detailEvent">
                <h6 class="gift_title font-weight-medium ellipsis">
                    {{ row.product_name }}
                </h6>
                <h6 class="sale_price font-weight-medium">
                    {{ formatPrice(row.sale_price) }}
                </h6>
            </div>

        </v-col>
    </v-card>
</template>

<script>
    import axios from '../../service/axios'

    export default {
        name: "giftcard",
        props: {
            row: {
                type: Object,
                require: false
            }
        },
        methods:{
            formatPrice(value) {
                try {
                    return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')+'원';
                }
                catch{
                    return value+'원';
                }
            },
            favoriteDelete(row){
                const params = {
                    accessKey: this.$cookies.get('token'),
                    options: row._id
                }
                axios.post('/api/product/favoriteDelete', params).then(res =>{
                    console.log(res.data.data)
                    this.$emit('delete-event');
                }).catch(error=>{
                    console.log('페스트 오류', error)
                });
            },
            detailEvent(){
                this.$emit('childs-event', this.$props.row);
            },
        }
    }
</script>

<style lang="scss">
    .gift_title{
        font-size: 0.875rem;
        letter-spacing: -0.05em;
    }
    .sale_price{
        font-size: 0.825rem;
        color: #5EB4F9;
        letter-spacing: -0.03em;
    }
    .favorite_position{
        position: absolute;
        top: 5px;
        right: 5px;
    }
    .favorite_card{
        border: .8px solid rgb(0 0 0 / 12%) !important;
    }
    .ellipsis{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
</style>

<style lang="scss" scoped>
    .v-sheet.v-card:not(.v-sheet--outlined) {
        box-shadow: none !important;
    }
</style>